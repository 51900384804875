<template>
    <div class="dropdown">
      <div class="dropdown-select" @click="toggleDropdown">
        {{label}}
        <span class="caret">{{ isDropdownOpen ? '▲' : '▼' }}</span>
      </div>
      <div class="dropdown-menu" v-if="isDropdownOpen">
        <div>
          <button @click="selectAll">{{ selected_all ? 'Unselect All' : 'Select All' }}</button>
        </div>
        <div  v-for="(item, index) in options" :key="index">
          <input  type="checkbox" :value=item v-model="SelectedValues"> {{ item }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props:{

      label: '',
      options:[]
    },
    data() {
      return {
        isDropdownOpen: false,
        selected_all: true,
        SelectedValues:this.options
      };
    },
    watch: {
      SelectedValues:{
        immediate: true,
        handler(newVal)
        {
          this.$emit('input', newVal);
        }
      },
      selected_all:{
        immediate:false,
        handler(newVal)
        {
          if (newVal)
            this.SelectedValues = this.options
          else
            this.SelectedValues = []
        }
      },
      options:{
        immediate:false,
        handler(newOption)
        {
          if (this.selected_all)
            this.SelectedValues = newOption
          else
            this.SelectedValues = []
        }
      }
    },
    methods: {
      toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      },
      selectAll() {
        this.selected_all = !this.selected_all;
      } 
    }
}
  </script>
  
  <style>
  /* Add styles here */
  .dropdown {
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  background-color: lightgray;

}

.dropdown-select {
  padding: 10px;
  cursor: pointer;
  user-select: none;
  font-size: 18px;
}

.dropdown-select .caret {
  float: right;

}

.dropdown-menu {
  display: block; 
  position: absolute;
  border-radius: 4px;
  width: 100%;
  font-size: 18px;
  max-height: 200px;
  overflow-y: auto;
  
}

.dropdown-item:first-child {
  border-top: none;
}

  </style>
  