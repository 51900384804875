<template>
<div style="display:flex;">
  <div style="width:25%; margin-right: 100px;">
  <form  @submit.prevent>
    <div class="metadata-container">
      <h4><b>Filter Experiments</b></h4>
    <div >
        <div>
            <label :style="{'font-size':fontSize}">Select Filter Type</label>
            <select v-model="query_type" class="custom-select" :style="{'font-size': fontSize}">
              <option>experiment_id</option>
              <option>static_metadata</option>
            </select>
        </div>
      </div>
    <!-- if query_type is experiment_id -->
    <div v-if="query_type == 'experiment_id'">
    <div >
        <MultiOptDropmenu ref="experiments" :options="experiments" :selected_all="true"  v-model="experiment" label="Select Experiment ID" ></MultiOptDropmenu>
    </div>
    

  </div>
    <!-- if query_type is static_metadata -->
    <div v-if="query_type == 'static_metadata'" >
      
 
    <MultiOptDropmenu ref="Experiment_Focus" :options="Experiment_Focus_list" v-model="Experiment_Focus" label="Select Experiment Focus" ></MultiOptDropmenu>
    <MultiOptDropmenu ref="Experiment_type" :options="Experiment_type_list" v-model="Experiment_type" label="Select Experiment Type" ></MultiOptDropmenu>
    <MultiOptDropmenu ref="Enabler" :options="Enabler_list" v-model="Enabler" label="Select Enabler" ></MultiOptDropmenu>
    <!-- <div >
        <input type="text" :style="{'font-size':fontSize}" v-model="Country_Input" style="width: 360px;" placeholder="Separate Countries by comma">
        <p style="margin-top: 10px;">Selected Countries: {{ Country}}</p>
    </div> -->
    <MultiOptDropmenu ref="Country" :options="Country_list" v-model="Country" label="Select Country" ></MultiOptDropmenu>
    <MultiOptDropmenu ref="UC" :options="UC_list" v-model="UC" label="Select UC"></MultiOptDropmenu>
    <MultiOptDropmenu ref="Safety_driver" :options="Safety_driver_list" v-model="Safety_driver" label="Select Safety Driver"></MultiOptDropmenu>
    <button @click="reset_filters"  style="font-size:18px; margin-top: 10px;">Reset Filters</button>
  </div>
   <!-- end if -->
  
   <div style="margin-top: 10px;">
      <label>{{ exp_no }}</label>
    </div>
  </div>
  <div v-if="loading" style="display: flex">
          <p style="font-size: 20px; margin-right: 20px;">Loading ...</p>
        <ring-loader :size="'30px'" :color="'#4285f4'"></ring-loader>
    </div>
  <div class="metadata-container">
    <h4><b>Filter Experiments Data</b></h4>
  <div v-if="step_enabled">
        <MultiOptDropmenu ref="stepref" :options="steps" v-model="step" label="Select Step" ></MultiOptDropmenu>
    </div>

    <div>
        <div style="width: 100%">
            <label :style="{'font-size': fontSize}">Select Data Group</label>
            <select v-model="group" class="custom-select" :style="{'font-size': fontSize}">
              <option v-for="g in groups" :key="g">{{ g }}</option>
            </select>
        </div>
    </div>
    <div>
        <!-- <div >
            <label :style="{'font-size': fontSize}">Select Data Key</label>
            <select v-model="subgroup" class="custom-select" :style="{'font-size': fontSize}">
              <option v-for="sg in subgroups" :key="sg">{{ sg }}</option>
            </select>
        </div> -->
        <MultiOptDropmenu ref="subgroupref" :options="subgroups" v-model="subgroup" label="Select Data Key" ></MultiOptDropmenu>
    </div>
  </div>
  <div class="metadata-container">
    <h4><b>Select Chart Type</b></h4>
    <div>
        <div>
            <!-- <label :style="{'font-size': fontSize}">Select Chart Type</label> -->
            <select v-model="chart" class="custom-select" :style="{'font-size': fontSize}">
              <option v-for="c in charts" :key="c">{{ c }}</option>
            </select>
        </div>
        
    </div>
  </div>
    <div style="display:flex;margin-top: 20px;">
      <b-button squared variant="primary" v-on:click="handleSubmit()" :style="{'font-size': fontSize}" style="margin-right: 10px;min-width: 100px;">Submit</b-button>
      <b-button squared variant="primary" v-on:click="exportgraph()" :style="{'font-size': fontSize}" style="margin-right: 10px;min-width: 150px;">Export Figure</b-button>
      <b-button squared variant="primary" v-on:click="exportdata()" :style="{'font-size': fontSize}" style="min-width:200px">Export Figure Data</b-button>
    </div>

  </form>
</div>
  <div style="width: 100%;">
    <div style=""><canvas id="myChart"></canvas></div>
  <div style="text-align:center;" :style="{'font-size': fontSize}"><p>{{description}}</p></div>
</div>
</div>
</template>

<script>

import { measurementsService } from '../_services'
import {Chart} from 'chart.js'
import 'chartjs-plugin-labels'
import config from 'config';
import MultiOptDropmenu from './MultiOptDropmenu.vue'
import RingLoader from 'vue-spinner/src/RingLoader.vue'
import {y_axis_units, x_axis_units} from './csvData.js'
export default {
  components:{
    RingLoader,
    MultiOptDropmenu
  },
  name:'QueryVehicular',
  data: function ()
  {
    return {
      experiment : [],
      query_type: '',
      experiments : [],
      Country_list: ['It', 'De', 'Fr', 'ESP','UK', 'BE', 'Undefined'],
      Experiment_Focus_list: ['SP2', 'SP5', 'SP6', 'SP7_Effects','SP7_Impact', 'SP8','Undefined'],
      Experiment_type_list: ['Road_public','WoOz_public', 'Track','WoOz_track', 'virt_Sim', 'Simulator','hard_Sim','Survey','Focus_Group', 'Undefined'],
      Enabler_list: ['V2V_direct', 'V2V_cellular', 'V2Cloud', 'V2I', 'TARA', 'Cybersecurity', 
      'Cloud', 'Localizaton', 'Positioning', 'ML_Toolkit', 'ML_Perception', 'ML_Decision',  'ML_monitoring', 'none', 'Undefined'],
      UC_list:this.getUC(),
      Safety_driver_list:['driver', 'outside','other', 'none', 'Undefined'],
      Country: [],
      Safety_driver: [],
      Enabler: [],
      Experiment_Focus: [],
      Experiment_type:[],
      step_enabled:true,
      exp_no:'',
      alert: '',
      UC:'',
      all_exp_group_dict: '',
      exp_for_step:'',
      static_metatdata_filters:['Experiment_Focus', 'Experiment_type', 'Enabler','Country','Safety_driver', 'UC'],
      step: [],
      steps:[],
      group:'',
      groups:[],
      subgroup:[],
      subgroups:[],
      data_filters:['experiment', 'step', 'group', 'subgroup'],
      chart_ref: null,
      submitted: false,
      is_array:false,
      is_multigroup: false,
      loading: false,
      fontSize: '20px',
      chart: 'Bar',
      charts: ['Bar', 'Pie'],
      unit: '',
      description: '',
      exp_step_group_meta_data: {},
      chart_meta_data: {
        type: '',
        data: {
          labels: [],
          datasets: [{
            label: [],
            data: [],
            backgroundColor: [],
            borderWidth: 1
          }]
        },
        options: {
        }
      }
    }
  },
  computed: {
    experiment_index: function(){
      if (experiment != ''){
        return 0
      }
      else{
        return this.experiments.indexOf(this.experiment)
      } 
    },
    allInputData: function(){
      return this.experiment,Date.now()
    },
    changeData(){
      let names = this.static_metatdata_filters.concat(this.data_filters)
      let variables = []
      names.forEach(name => 
      {
        variables.push(this[name])
      })
      return variables
    },
    change_static_metadata(){
     

      let variables = []
      this.static_metatdata_filters.forEach(name => 
      {
        variables.push(this[name])
      })
      return variables
    }
  },
  created: function(){
    config.apiUrl = localStorage.apiUrl
  },
  methods: {
    getUC(){
      let list = []
      for (let i=1; i<=27; i++)
        {
          list.push('M.' + i)
        }
        for (let i=1; i<=18; i++)
        {
          list.push('U.' + i)
        }
        for (let i=1; i<=5; i++)
        {
          list.push('R.' + i)
        }
        for (let i=1; i<=4; i++)
        {
          list.push('P.' + i)
        }
        list.push('Undefined')
        return list
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    empty_chart_data() {
      this.chart_meta_data.data.datasets[0].data = []
      this.chart_meta_data.data.labels = []
      this.chart_meta_data.data.datasets[0].backgroundColor = []
      this.chart_meta_data.options = {}
      this.description = ''
      
    },
    reset_filters(){
      this.static_metatdata_filters.forEach(item => {
        this.$refs[item].selected_all = true
        this.$refs[item].SelectedValues = this[item + '_list']
      })
    },
    rainbow(n) {
    return 'hsl(' + n + ',100%,50%)'
    },
    get_exps_from_static_metadata()
    {
      let condition = false
      this.static_metatdata_filters.forEach(item=>{
        condition = condition || this[item].length == 0 
      })
      if (condition)
      {
        // this.exp_no = "Please select an item for each static metdata!"
        this.experiment = []
      }
      else
      {
        this.exp_no = ""
        measurementsService.get_exp_from_static_metadata_offline(this, this.static_metatdata_filters).then(res1 => {
          this.experiment = res1
        }
        )
      }
     
    },
    handleSubmit: function(){
      let exp_cond = this.query_type == 'static_metadata' || (this.query_type == 'experiment_id' && this.experiment != '')
      if (this.subgroup.length > 0 && this.group != '' && exp_cond && this.chart != '')
      {
        this.chart_ref && this.chart_ref.destroy();
        this.empty_chart_data()
        var size = 18
        var key_len = Object.keys(this.exp_step_group_meta_data).length - 2
        if (this.chart == 'Bar' || this.chart == 'Pie')
        {
              
              if (this.chart == 'Bar' )
              {
                
                this.chart_meta_data.options = {
                  legend:{display:false},
                  title:
                {
                  display: true,
                  text: this.group,
                  fontSize: size,
                  padding: 30
                },
                scales: {
                  yAxes: [{
                    scaleLabel: {
                    display: true,
                    labelString: this.exp_step_group_meta_data['unit'][0],
                    fontSize: size // Adjust the font size as needed
                },
                ticks:
                {
                  beginAtZero: true,
                  fontSize: size
                }
              }],
              xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: this.exp_step_group_meta_data['unit'][1],
                    fontSize: size // Adjust the font size as needed
                },
                ticks:
                {
                  fontSize: size
                } 
              }]
                }   
              }
                if (this.is_array)
                {
                  Chart.defaults.global.plugins.labels = {render:'value', fontSize: size/2.0}
                }
                else
                {
                  Chart.defaults.global.plugins.labels = {render:'value', fontSize: size}
                }
                  

                this.chart_meta_data.type = 'bar'
                
                // this.chart_meta_data.options.scales.yAxes[0].scaleLabel.labelString = this.unit

                // this.chart_meta_data.data[0].yValueFormatString="###.#"
                var ic = 0
                Object.keys(this.exp_step_group_meta_data).forEach((key, i) => {
                  if (key != 'unit' && key != 'description')
                  {
                    this.chart_meta_data.data.labels.push(key)
                    this.chart_meta_data.data.datasets[0].data.push(parseInt(this.exp_step_group_meta_data[key]))
                    if (this.is_array)
                    {
                      this.chart_meta_data.data.datasets[0].backgroundColor.push('blue')
                    }
                    else
                    {
                      this.chart_meta_data.data.datasets[0].backgroundColor.push(this.rainbow(parseInt((ic / key_len)*360)))
                    }
                    
                    ic += 1
                  }
                })
                              
              }
              else if (this.chart == 'Pie')
              {
                this.chart_meta_data.options = {title:
                {
                  display: true,
                  text: this.group,
                  fontSize: size
                }
               }
                Chart.defaults.global.plugins.labels = {render: "percentage", precision: 2, fontColor: 'white'}
                this.chart_meta_data.type = 'pie'
                // this.chart_meta_data.data[0].yValueFormatString="##.#'%'"
                let total_tests = 0
                var ic = 0
                Object.keys(this.exp_step_group_meta_data).forEach((key, i) => {
                  if (key != 'unit' && key != 'description')
                  {
                    if (parseInt(this.exp_step_group_meta_data[key]) > 0)
                    {
                      this.chart_meta_data.data.labels.push(key)
                      this.chart_meta_data.data.datasets[0].data.push(parseInt(this.exp_step_group_meta_data[key]))
                      this.chart_meta_data.data.datasets[0].backgroundColor.push(this.rainbow(parseInt((ic / key_len)*360)))
                      total_tests = total_tests + parseInt(this.exp_step_group_meta_data[key])
                      ic += 1
                    }
                  }
                })
                this.chart_meta_data.data.datasets[0].data = this.chart_meta_data.data.datasets[0].data.map(element => { if (!isNaN(element)){
                  return Math.round((element / total_tests) * 10000)/100 
                }
                else{
                  return 0
                }
                  })               
          }          
        
      }
      var ctx = document.getElementById('myChart').getContext('2d');
      this.chart_ref = new Chart(ctx, this.chart_meta_data)
      }
      else{
        window.alert('Please Select all options in the form!')
      }
     
     
    },
    exportgraph: function()
    {
      if (this.chart_ref)
      {
        var canvas = document.getElementById("myChart")
        var a  = document.createElement('a')
        a.href = canvas.toDataURL("image/png")
        a.download = 'image.png';
        a.click()
        
      }
      else
      {
        window.alert("The data has not been submitted yet!")
      }
    },
    exportdata: function()
    {
      if (this.chart_ref)
      {
        const csv_data = []
        csv_data.push(Object.keys(this.exp_step_group_meta_data))
        csv_data.push(Object.values(this.exp_step_group_meta_data))
        let csvContent = ''
        csv_data.forEach(row => {csvContent += row.join(',') + '\n'})
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
        const objUrl = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = objUrl
        link.download =  this.group + '.csv'
        link.click()
      }
      else
      {
        window.alert("No chart has been rendered!")
      }
    }
  },
  watch: {
    change_static_metadata: function(newVal)
    {
        this.data_filters.forEach(df => 
        {
          this.df = ''
        })
        this.get_exps_from_static_metadata()
    },
    allInputData: function(){

    },
    query_type:function(val)
    {

      if (val == 'static_metadata')
      {
        
        this.data_filters.forEach(name => {
        this.name = ''
      })
        this.get_exps_from_static_metadata()
      }
      else if(val == 'experiment_id')
      {
        this.static_metatdata_filters.forEach(name => {
        this.name = []
      })
        measurementsService.getExperimentsName().then(res =>{ this.experiments = res
        this.exp_no = res.length + " experiments Found!"        
      })
      }
    },
    changeData: function (){
      if (this.chart_ref)
      {
        this.chart_ref.destroy()
        this.empty_chart_data()
        this.chart_ref = null
      }
    },
    Country_Input: function(newCountry_Input){
      let filtered_countries = newCountry_Input.split(',').map(item => item.trim())
      this.Country = filtered_countries.filter(item => item != "")

    },
    experiment: function(new_experiment){
      this.steps = []
      // this.$refs.stepref.selected_all = false
      this.groups = []
      this.group = ''
      if (new_experiment.length > 0){
        this.loading = true
        measurementsService.aggragate_exp_data(new_experiment).then(res => {
          this.loading = false
          let msg_end = ''
          if (this.query_type == 'static_metadata')
            msg_end = 'found!'
          else
            msg_end = 'selected!'
          if (new_experiment.length > 1){
          this.all_exp_group_dict = res
          this.exp_no =new_experiment.length + " Experiment " + msg_end
          this.step_enabled = false
          this.groups = Object.keys(this.all_exp_group_dict)
          }
          else
          {
            this.step_enabled = true
            this.steps = Object.keys(res)
            this.all_exp_group_dict = res
            this.exp_for_step = res
            this.exp_no =new_experiment.length + " Experiment " + msg_end
          }
         
        })
      }
      else{
        if (this.query_type == 'static_metadata')
          this.exp_no  = 'No experiment found!! Please modify the static metadata'
        else
          this.exp_no  = 'No experiment selected!'
      }

        
    },
    step: function(newstep){
      this.group = ''
      this.groups = []
      if (newstep.length > 0)
      {
        var exp_group_dict = {}
        newstep.forEach(s =>
        {
          for(var grp in this.exp_for_step[s]){
            if (grp in exp_group_dict)
            exp_group_dict[grp].values = measurementsService.sum_two_groups(exp_group_dict[grp].values, this.exp_for_step[s][grp].values)
            else
            exp_group_dict[grp] = this.exp_for_step[s][grp]
            
          }
        })
        this.all_exp_group_dict = exp_group_dict
        this.groups = Object.keys(this.all_exp_group_dict)
      } 
    },

    group: function(val)
    {
      this.subgroup = []
      this.subgroups = []
      if (val != '')
      {
        let group_keys = Object.keys(this.all_exp_group_dict[val].values)
        this.$refs.subgroupref.selected_all = !Array.isArray(this.all_exp_group_dict[this.group].values[group_keys[0]])
        this.subgroups = Object.keys(this.all_exp_group_dict[val].values)
      }
    },
    subgroup: function(newSubgroup)
    {
      if (newSubgroup.length > 0)
      {
        if (Array.isArray(this.all_exp_group_dict[this.group].values[newSubgroup[0]]) && newSubgroup.length > 1)
          {
            // this.subgroup = []
            // Vue.set(this, 'subgroup', []);
            window.alert('Please select just one "Data Key" for array-like groups such as '+ this.group +'.')
            this.$refs.subgroupref.isDropdownOpen = false
            this.$refs.subgroupref.selected_all = false
            this.$refs.subgroupref.SelectedValues = []
          }
        else
        {

          this.exp_step_group_meta_data = {}
          if (Array.isArray(this.all_exp_group_dict[this.group].values[newSubgroup[0]]))
          {
            this.all_exp_group_dict[this.group].values[newSubgroup[0]].forEach((el,i) => {
              let k = (i*5).toString() + '-' + ((i+1)*5).toString() +' kph'
              this.exp_step_group_meta_data[k] = el
              this.is_array = true
            })
          }
          else
          {
            newSubgroup.forEach(subgrp => {
          this.exp_step_group_meta_data[subgrp] = this.all_exp_group_dict[this.group].values[subgrp]})
          this.is_array = false
          }
        this.exp_step_group_meta_data['unit'] = [y_axis_units[this.group], x_axis_units[this.group]]
        this.exp_step_group_meta_data['description'] = ''
      }
        }
      
      
    }
    
 }
}
</script>

<style>
.metadata-container {
    display: inline-block; 
    border: 2px solid #ccc;
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
    width: 120%;
}

.metadata-container h3 {
    margin-bottom: 15px;
}
.main-form
{
  display: flex;
  flex-flow:row wrap;
}
</style>
