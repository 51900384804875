import config from 'config';
import axios from 'axios';
import { filter } from 'jszip';

export const measurementsService = {
  getExperimentsName, getGroups, getSteps, getMetadata, getSubgroups, get_exp_from_static_metadata, aggragate_exp_data, sum_two_groups,get_exp_from_static_metadata_offline
};

function getSteps(experiment)
{
  var query = `${config.apiUrl}/experiments/${experiment}/group`
  return axios.get(query).then(res => {
    var steps = res.data.history.map(d=>d.step.toString())
    return steps
  })
  .catch(err => {
    if (err.response.status == 403)
      return Promise.reject(new Error("Error 403: Permission denied, this user does not have the required permissions"))
    else
      return err
    })
}


function getSubgroups(experiment, stepIndex, group)
{
  
  var query = `${config.apiUrl}/experiments/${experiment}/group`
  
  return axios.get(query).then(res => {
    var ret = {subgroups:[], is_array:false, multi_subgroup:false}
    if (stepIndex == -1){
      for (let i=0;i< res.data.history.length; i++){
        var keys = Object.keys(res.data.history[i].groups[group].values)
        if(keys.length > 0){
          ret['is_array'] = Array.isArray(res.data.history[i].groups[group].values[keys[0]])
          ret['multi_subgroup'] = keys.length > 1
          ret['subgroups'] = keys
          break;
        }
      }
    }
    else
    {
      var keys = Object.keys(res.data.history[stepIndex].groups[group].values)
      ret['is_array'] = Array.isArray(res.data.history[stepIndex].groups[group].values[keys[0]])
      ret['multi_subgroup'] = keys.length > 1
      ret['subgroups'] = keys
    }
    return ret
  })
  .catch(err => {
    console.log(err)
    // if (err.response.status == 403)
    //   return Promise.reject(new Error("Error 403: Permission denied, this user does not have the required permissions"))
    // else
    //   return err
    })
}
function getGroups(experiment, stepIndex)
{
  var query = `${config.apiUrl}/experiments/${experiment}/group`
  
  return axios.get(query).then(res => {
    var groups = []
    if (stepIndex == -1){
      for (let i=0;i<res.data.history.length; i++){
        for(var grp in res.data.history[i].groups){
          if(Object.keys(res.data.history[i].groups[grp].values).length > 0){
            if (groups.includes(grp)== false){
              groups.push(grp)          
            }
          }
        }
      }
    }
    else
    {
      for(var grp in res.data.history[stepIndex].groups){
        if(Object.keys(res.data.history[stepIndex].groups[grp].values).length > 0){
          groups.push(grp)
        }
      }
    }
    return groups
  })
  .catch(err => {
    if (err.response.status == 403)
      return Promise.reject(new Error("Error 403: Permission denied, this user does not have the required permissions"))
    else
      return err
    })
}

function sum_two_arrays(array1, array2)
{
  let maxLength = Math.max(array1.length, array2.length);
  let result = [];

  for (let i = 0; i < maxLength; i++) {
      let val1 = array1[i] || 0; // If array1 is shorter, use 0
      let val2 = array2[i] || 0; // If array2 is shorter, use 0
      result.push(val1 + val2);
  }

  return result;
}
function sum_two_groups(obj1, obj2)
{
  let result = {};
    // Add all values from obj1 to result
    for (let key in obj1) {result[key] = obj1[key]}
    // Add values from obj2, summing them if the key already exists in result
    for (let key in obj2) {
      if (result.hasOwnProperty(key))
        {
          if (Array.isArray(result[key]) && Array.isArray(obj2[key]))
              result[key] = sum_two_arrays(result[key], obj2[key])
          else
            result[key] = result[key] + obj2[key]
        }
      else
        result[key] = obj2[key]
    }
    return result
}

function aggragate_exp_data_offline(experiment_ids)
{
  var all_exp_group_dict = {}
  if (experiment_ids.length > 1)
  {
    let filter_experiments = []
    let promises = [];
    experiment_ids.forEach(el => {
        var query = `${config.apiUrl}/experiments/${el}/group?select["history"]`;
        // Push the promise from axios.get into the promises array
        promises.push(axios.get(query));
    })
    experiment_ids.forEach(el => {
      filter_experiments.push({"_id":el})}
      )

  return Promise.all(promises).then(results => {
    results.forEach(res => {
      res.data.history.forEach((el) => {
          for (let grp in el.groups) {
            if (Object.keys(el.groups[grp].values).length > 0)
                {
                  if (grp in all_exp_group_dict) {
                  
                    all_exp_group_dict[grp] = sum_two_groups(all_exp_group_dict[grp], el.groups[grp].values);
                } else {
                 
                    all_exp_group_dict[grp] = el.groups[grp].values;
                        }
                }
             
          }
      });
    
  })
  return all_exp_group_dict
  })
}
else
{
  var query = `${config.apiUrl}/experiments/${experiment_ids[0]}/group`
  return axios.get(query).then( res => {
    res.data.history.forEach((el, i) => {
      let step_group_dict = {}
   
      for (let grp in el.groups) {
        if (Object.keys(el.groups[grp].values).length > 0)
            {
              step_group_dict[grp] = el.groups[grp].values;
            }
      }
      all_exp_group_dict['Step ' + (i+1)] = step_group_dict
  });
  return all_exp_group_dict
  }
  )
}
}
function aggragate_exp_data(experiment_ids)
{
  var all_exp_group_dict = {}
  if (experiment_ids.length > 1)
  {
    let filter_experiments = []
    experiment_ids.forEach(el => {
      filter_experiments.push({"_id":el})}
      )

  var query = `${config.apiUrl}/experiments/aggregated_experiments?filter=${JSON.stringify({"$or":filter_experiments})}&groups=True`
  return axios.get(query).then(res=> 
    {
      return res.data.aggregated_histories
    })
}
else
{
  var query = `${config.apiUrl}/experiments/${experiment_ids[0]}/group`
  return axios.get(query).then( res => {
    res.data.history.forEach((el, i) => {
      let step_group_dict = {}
   
      for (let grp in el.groups) {
        if (Object.keys(el.groups[grp].values).length > 0)
            {
              step_group_dict[grp] = el.groups[grp]
            }
      }
      all_exp_group_dict['Step ' + (i+1)] = step_group_dict
  });
  return all_exp_group_dict
  }
  )
}
}
function get_exp_from_static_metadata_offline(obj, name_list) {
  
    const query = `${config.apiUrl}/experiments/?select=["_id","metadata"]&limit=-1&sort={"_id":"asc"}`;
 
    return axios.get(query).then(res => {
      const filteredData = res.data.docs.filter(el => {
        return name_list.every(name => {
         
          const matchFilter = obj[name]
            .filter(opt => opt != 'Undefined')
            .some(opt => {
              const paramName = name == 'UC' ? `${name}.${opt}` : name == 'Country' ? name : `${name}_${opt}`;
              const filterValue = name == 'Country' ? opt : 1;
    
              const metadataEntry = el.metadata.find(entry => entry.name == paramName);
              return metadataEntry && metadataEntry.value == filterValue;
            });
          const hasUndefined = obj[name].includes('Undefined');
          let undefMatchFilter = false
          if (hasUndefined) {
            undefMatchFilter = obj[name + '_list']
              .filter(undefOpt => undefOpt != 'Undefined')
              .every(undefOpt => {
                const paramName = name == 'UC' ? `${name}.${undefOpt}` : name == 'Country' ? name : `${name}_${undefOpt}`;
    
                const metadataEntry = el.metadata.find(entry => entry.name == paramName);
                if (name != 'Country')
                  return metadataEntry && metadataEntry.value != 1;
                return metadataEntry && metadataEntry.value != undefOpt
              });
          }
    
          return matchFilter || undefMatchFilter;
        });
      });
      return filteredData.map(el => el._id);
    })
    
    

}
function get_exp_from_static_metadata(obj, name_list)
{
  // var experiment_filters = []
  // var filters = []
  var filters = {}
  name_list.forEach(name => {
    if (obj[name].length > 0)
    {

      filters[name] = []
        if (name == 'UC')
      {
        obj[name].forEach(opt => {
          if (opt!='Undefined')
          filters[name].push({"name":  name + '.' + opt, "value": 1})
        })
        
      }
      else if (name == 'Country')
      {
        obj[name].forEach(opt => {
          if (opt!='Undefined')
          filters[name].push({"name":  name, "value": opt})
        })
      }
      else
      {
        obj[name].forEach(opt => {
          if (opt!='Undefined')
          filters[name].push({"name":  name + '_' + opt, "value": 1})
        })
      }
    }
    
  })

  let group_filters = []
  for(let name in filters)
  {
    group_filters.push({"$or": filters[name].map(filter => {return { "metadata": { "$elemMatch": filter } }})})
  }
  var queryFilters = {"$and": group_filters}
  var query = `${config.apiUrl}/experiments/?select=["_id","metadata"]&filter=${JSON.stringify(queryFilters)}&limit=-1&sort={"_id":"asc"}`
  return axios.get(query).then(res => {
      var experiment_ids = []
      
      res.data.docs.forEach((el, i) => {
        // experiment_filters.push({"_id": el._id})
        experiment_ids.push(el._id)
      })
      return experiment_ids
      })
      
}
function getMetadata(experiment, stepIndex, group, subgroup, is_array)
{
  var query = `${config.apiUrl}/experiments/${experiment}/group?groups=["${group}"]`
  return axios.get(query).then(res => {
    var meta_data = {}
    meta_data['unit'] = res.data.history[0].groups[group].unit
    meta_data['description'] = res.data.history[0].groups[group].description
    if (stepIndex == -1)
    {
      if(subgroup == 'all')
      {
        for (let i = 0; i < res.data.history.length; i++)
        {
          if (Object.keys(res.data.history[i].groups[group].values).length > 0)
          {
            for (key in res.data.history[i].groups[group].values)
            {
              if (key in  meta_data)
                meta_data[key] += res.data.history[i].groups[group].values[key]
              else
                meta_data[key] = res.data.history[i].groups[group].values[key]
            }
          }
      }
      }
      else
      {
        for (let i = 0; i < res.data.history.length; i++)
        {
          if (Object.keys(res.data.history[i].groups[group].values).length > 0 && subgroup in res.data.history[i].groups[group].values)
          {
            if (is_array)
            {
              for (let j = 0; j < res.data.history[i].groups[group].values[subgroup].length; j++)
              {
                if (j in meta_data)
                  meta_data[j] += res.data.history[i].groups[group].values[subgroup][j]
                else
                  meta_data[j] = res.data.history[i].groups[group].values[subgroup][j]
              }
            }
            else
            {
              var key = 'Step ' + res.data.history[i].step
              meta_data[key] = res.data.history[i].groups[group].values[subgroup]
            }
            
          }
        }
      }
       
    
      return meta_data
      
    }
    else{

      if (subgroup =='all')
      {
        for (key in res.data.history[stepIndex].groups[group].values)
        {
          meta_data[key] = res.data.history[stepIndex].groups[group].values[key]
        }
        return meta_data
      }
      else
      {
        if (is_array)
        {
          for (let i = 0; i < res.data.history[stepIndex].groups[group].values[subgroup].length; i++)
          {
            var key = i
            meta_data[key] = res.data.history[stepIndex].groups[group].values[subgroup][i]
          }
        }
        else
        {
          var key_string = 'Step ' + res.data.history[stepIndex].step
          meta_data[key_string] = res.data.history[stepIndex].groups[group].values[subgroup]
        }
        return meta_data
      }
     
    }
  })
}


function getExperimentsName()
{
  var query = `${config.apiUrl}/experiments?select=["_id"]&limit=-1&sort={"_id":"asc"}`
  return axios.get(query).then(res => {
    
    var experimentsName = res.data.docs.map(d=>d._id)
    return experimentsName
  })
  .catch(err => {
    if (err.response.status == 403)
      return Promise.reject(new Error("Error 403: Permission denied, this user does not have the required permissions"))
    else
      return err
    })
}

