<template>
<div style="display:flex">
<div class="sidenav">
         <div class="login-main-text">
           <div class="w-75"><img src="@/assets/logo.png" class=".img-fluid" style="width:100%;"></div>
           <div class="col-xs-12" style="height:50px;"></div>
            <h2>User Interface for Visualizing Experimental Meta-data</h2>
            <p>Login here to have access.</p>
         </div>
</div>

      <div class="main" style="width: 100%">
         <div class="col-md-6 col-sm-12">
            <div class="login-form">
               <form @submit.prevent="handleSubmit">
                  <div class="form-group">
                     <label>Username</label>
                     <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" placeholder="Username" />
                     <div v-show="submitted && !username" class="invalid-feedback">Username is required</div>
                  </div>
                  <div class="form-group">
                     <label>Password</label>
                     <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" placeholder="Password" />
                     <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                  </div>
                  <div class="form-group">
                     <label>Tenant</label>
                     <select v-model="tenant" class="form-control" :class="{ 'is-invalid': submitted && !tenant }" placeholder="Tenant">
                     <option>TEST Server</option>
                     <option>PROD Server</option>
                    </select>
                     <!-- <input type="text" v-model="tenant" name="tenant" class="form-control" :class="{ 'is-invalid': submitted && !tenant }" placeholder="Tenant" /> -->
                     <div v-show="submitted && !tenant" class="invalid-feedback">Tenant is required</div>
                  </div>
                  <button class="btn btn-primary" :disabled="loggingIn">Login</button>
                  <img v-show="loggingIn" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
               </form>
                  <br>
                  <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
            </div>
         </div>
      </div>      
</div>
</template>

<script>
import config from 'config';
export default {
    data () {
        return {
            username: '',
            password: '',
            tenant:'',
            submitted: false
        }
    },
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        },
        alert () {
            return this.$store.state.alert
        }
    },
    created () {
    },
    methods: {
        handleSubmit (e) {
            this.submitted = true;
            const { username, password} = this;
            var tenant = this.tenant;
            const { dispatch } = this.$store;
            if (username && password && tenant) {
                if (tenant == 'TEST Server')
                {
   
                    tenant = 'EM-test'
                    config.apiUrl = 'https://emdb-test.hi-drive-cdb.eu/v1'
                    localStorage.apiUrl = 'https://emdb-test.hi-drive-cdb.eu/v1'; 

                }
                else if (tenant == 'PROD Server')
                {
                    tenant = 'EM-prod'
                    config.apiUrl = 'https://emdb.hi-drive-cdb.eu/v1'
                    localStorage.apiUrl  = 'https://emdb.hi-drive-cdb.eu/v1'
                }
                dispatch('authentication/login', { username, password, tenant});
            }
        }
    }
};
</script>

<style>
body {
    font-family: "Lato", sans-serif;
}

.main-head{
    height: 150px;
    background: #FFF;
   
}

.sidenav {
    height: 100%;
    background-color: #422cdc;
    overflow-x: hidden;
    padding-top: 10px;
}


.main {
    padding: 0px 10px;
}

@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
}

@media screen and (max-width: 450px) {
    .login-form{
        margin-top: 10%;
    }

    .register-form{
        margin-top: 10%;
    }
}

@media screen and (min-width: 768px){
    .main{
        margin-left: 30%; 
    }

    .sidenav{
        width: 30%;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
    }

    .login-form{
        margin-top: 40%;
    }

    .register-form{
        margin-top: 20%;
    }
}


.login-main-text{
    margin-top: 5%;
    padding: 60px;
    color: #fff;
}

.login-main-text h2{
    font-weight: 300;
}

.btn-black{
    background-color: #000 !important;
    color: #fff;
  }

</style>
