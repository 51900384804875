<template>
    <div>
      <h4><b>An overview of EMDB data:</b></h4> 
        <div>
        </div>
        <div v-if="loading" style="display: flex">
          <p style="font-size: 20px; margin-right: 20px;">Loading ...</p>
        <ring-loader :size="'30px'" :color="'#4285f4'"></ring-loader>
      </div>
        <div style="margin-top: 30px;" v-if="!loading">
      <div>
        <div>
            <div>

            <p class="animated-number">
              
              - Conducted <strong class="number">{{ Math.floor(exp_no) }}</strong> experiments.</p>
            </div>
              
        </div>
      <div>
        <div ></div>
        <p class="animated-number">
                    - Travelled <strong class="number">{{ Math.floor(total_driven_miles) }}</strong> kilometers.</p>
      </div>
      <div>
        <div ></div>
        <p class="animated-number">
                    - Recorded <strong class="number">{{ Math.floor(total_hours_driven/3600) }}</strong> hours of driving.</p>
      </div>
      <div>
        <div ></div>
        <p class="animated-number">
                    - Completed <strong class="number">{{ Math.floor(total_number_of_tests) }} </strong> test runs.</p>
      </div>
    </div>
    </div>
    <div style="margin-top: 30px;font-size: 20px;" v-if="!loading">
      <p>
            <router-link to="/experimentHistory">More queries ...</router-link>
        </p>
    </div>
       
        
    </div>
</template>

<script>
import { measurementsService } from '../_services'
import config from 'config';
import RingLoader from 'vue-spinner/src/RingLoader.vue'
export default {
  components:
  {
    RingLoader,
  },
  name:'Welcome',
  data: function()
  {
    return{
        exp_no:0,
        experiments:'',
        duration: 2000,
        interval:100,
        all_exp_group_dict:'',
        total_driven_miles:0,
        total_hours_driven:0,
        total_number_of_tests:0,
        loading: true
    }
  },
  methods:
  {
    animateNumber(var_name, target)
    {  
        let  steps = this.duration / this.interval;
        let increment = target/ steps;
        const intervalId = setInterval(() => {
        this[var_name] += increment;
        if (this[var_name] >= target) {
            this[var_name] = target;
            clearInterval(intervalId);
        }
        }, this.interval);
    }

  },
  created: function()
  {
    config.apiUrl = localStorage.apiUrl
    
    measurementsService.getExperimentsName().then(res =>{ 
        this.experiments = res
        
    })


  },
  watch:
  {
    experiments: function(new_experiment)
    {
      this.loading = true
        measurementsService.aggragate_exp_data(new_experiment).then(res => {
          this.loading = false
          this.all_exp_group_dict = res
          this.animateNumber('exp_no', this.experiments.length)
          this.animateNumber('total_driven_miles', res['Travelled distance'].values['Distance_overall'])
          this.animateNumber('total_hours_driven', res['Driving time'].values['Time_overall'])
          this.animateNumber('total_number_of_tests', res['Overall number of test runs in experiment'].values['Experiment_tests'])
        })
    }
  }
};
</script>
<style>
.green-circle {
  width: 15px;
  height: 15px;
  background-color: #172b4d;
  border-radius: 50%;
  display:inline-block;
  margin-right: 1px; /* Adjust the margin as needed */
  margin-bottom: 3px;
}
.animated-number {
  display: inline-block;
  font-size: 20px; /* Adjust the font size as needed */
  color: #172b4d;
  margin-bottom: 15px;
  padding-left: 20px;
  /* font-family:"Montserrat",sans-serif */

}
.number {
color: #422cdc;
}

</style>
