<template>
  <div>
  <b-navbar toggleable="sm" type="dark">
    <b-navbar-nav>
      <b-navbar-brand href="#">EMDB GUI</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-nav-item to="/">Overview</b-nav-item>
      
      <b-nav-item to="/experimentHistory">Experiment History</b-nav-item>
      
      <b-nav-item @click="logout">Logout</b-nav-item>
    </b-navbar-nav>
  </b-navbar>
  </div>
</template>

<script>
export default {
  name:'navbar',
  methods: {
    logout () {
      this.$store.dispatch('authentication/logout');
    }
  }

}
</script>

<style>
.navbar-dark {
  background-color:#422cdc;
}
</style>
