import Vue from 'vue';
import Router from 'vue-router';

import LoginPage from '../login/LoginPage'
import Dash from '../home/Dash'

import Welcome from '../home/Welcome'
import ExperimentHistory from '../home/ExperimentHistory'

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', component: Dash, children: [{path:'', component: Welcome}] },
    { path: '/experimentHistory', component: Dash, children: [{path:'', component: ExperimentHistory}] },
    { path: '/login', component: LoginPage },
    // otherwise redirect to home
    { path: '*', redirect: '/' }
  ]
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const loggedIn = localStorage.getItem('user-token');
  if (!loggedIn && to.path != '/login') 
      return next('/login');
  return next();
})
