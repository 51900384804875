// csvData.js

// Define the CSV content
const csvContent = `Experiment Metadata,Y axis unit,X axis unit
Start Date,,
End Date,,
Name of Experiment,,
Is the experiment finished?,Frequency,
Number of uploads for this experiment,Frequency,
Company,,
Primary Focus of experiment,,
Overall number of test runs in experiment,Frequency,
Time of tests in the experiment,Frequency,Time (hour)
Duration of test in the experiment,Frequency,Time (minute)
Country in which experiment took place,,
Location,,
Experiment scope,Frequency,
Experiment type,,
Involved enablers,,
Number of tested persons - gender,Frequency,
Number of tested persons - age,Frequency,Age (year)
Safety Driver present (Driver Seat),,
Number of subjects / tested persons on / at,Frequency,
Temperature,Frequency,Temperature (°C)
Road condition,Frequency,
Weather / Precipitation Condition,Frequency,
Lighting conditions,Frequency,
Addressed Use Case,,
Number of encountered driving scenarios (Overall),Frequency,
Number of encountered driving scenarios (Manual Driving),Frequency,
Number of encountered driving scenarios (ADF Driving),Frequency,
Number of encountered driving scenarios (ADF Driving with enabler),Frequency,
Travelled distance,km,
Driving time,sec,
Number of Activation in Experiments,Frequency,
Number of Deactivation in Experiments,Frequency,
Number of TOR in Experiments,Frequency,
Number of MRM in Experiments,Frequency,
Number of interventions by safety driver,Frequency,
Operation time ADF,Frequency,
Operation time ADF Enabler,Frequency,
Speed Histogram,Frequency,Speed (kph)
Travelled distance on Roads with X number of lanes,km,
Number of encountered crossing traffic sections,Frequency,
Travelled distance on roads with speed limit,km,Speed limit (km/h)
Number of accidents during experiment,Frequency,
Number of safety critical situation / incidents during experiments,Frequency,`;

// Initialize dictionaries
const y_axis_units = {};
const x_axis_units = {};

// Split the CSV content by lines
const rows = csvContent.split('\n');

// Iterate over rows, excluding the header row
for (let i = 1; i < rows.length; i++) {
    const row = rows[i].split(',');

    // Add key-value pair to y_axis_units (first column as key, second column as value)
    y_axis_units[row[0]] = row[1];

    // Add key-value pair to x_axis_units (first column as key, third column as value)
    x_axis_units[row[0]] = row[2];
}

// Export dictionaries
export { y_axis_units, x_axis_units };